<template>
  <div>
    <PageHeader />
    <b-card>
      <div v-if="!promotionCode" class="text-center">
        <b-spinner />
      </div>
      <PromotionCodeForm v-else :promotion-code="promotionCode" />
    </b-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'PromotionCodeEditPage',
  page: {
    title: 'แก้ไขโค้ดโปรโมชั่น',
  },
  components: {
    PromotionCodeForm: () => import('@components/promotion-code/Form')
  },
  data() {
    return {
      promotionCode: null
    }
  },
  async created() {
    const promotionCode = await this.findPromotionCode(this.$route.params.id)
    if(promotionCode) {
      this.promotionCode = promotionCode
    } else {
      await this.$router.push('/promotion-code')
    }
  },
  methods: {
    ...mapActions(['findPromotionCode', 'notifyError'])
  }
}
</script>

<style scoped>

</style>